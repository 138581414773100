/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppProvider,
  ButtonGroup,
  DataTable,
  Button,
  Spinner,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useState, useEffect } from "react";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faRefresh,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/config";
import { ENDPOINT } from "../../common/config";

const UserDashboard = () => {
  const toastr_options: ToastOptions = {
    position: "top-right",
    autoClose: 500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const [username, setUsername] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).username;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [userToken, setUserToken] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).token;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [totalPayments, setTotalPayments] = useState(0);
  const [totalPaymentsDetails, setTotalPaymentsDetails] = useState(0);
  const [totalPayouts, setTotalPayouts] = useState(0);
  const [paymentsIndex, setPaymentsIndex] = useState(1);
  const [paymentsDetailsIndex, setPaymentsDetailsIndex] = useState(1);
  const [paymentsMaxIndex, setPaymentsMaxIndex] = useState(1);
  const [paymentsDetailsMaxIndex, setPaymentsDetailsMaxIndex] = useState(1);
  const [paymentsPageSize, setPaymentsPageSize] = useState(10);
  const [paymentsDetailsSize, setPaymentsDetailsSize] = useState(10);
  const [payoutsPageSize, setPayoutsPageSize] = useState(10);
  const [payoutsIndex, setPayoutsIndex] = useState(1);
  const [payoutsMaxIndex, setPayoutsMaxIndex] = useState(1);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [paymentsCalled, setPaymentsCalled] = useState(false);
  const [fetchingPayments, setFetchingPayments] = useState(false);
  const [payments, setPayments] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [payoutsCalled, setPayoutsCalled] = useState(false);
  const [fetchingPayouts, setFetchingPayouts] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPayoutAmount, setTotalPayoutAmount] = useState(0);
  const [totalPayoutPayments, setTotalPayoutPayments] = useState(0);
  const [totalPayoutPaymentsComb, setTotalPayoutPaymentsComb] = useState(0);
  const [selectedDate, setSelectedDate] = useState(() => {
    var currentDate = new Date();
    var fullYear = currentDate.getFullYear();
    var month: any = currentDate.getMonth();
    var day: any = currentDate.getDate();
    month = month + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return fullYear + "-" + month + "-" + day;
  });

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [sublistData, setSublistData] = useState<{ [key: number]: any[] }>({});
  const [loadingSublist, setLoadingSublist] = useState<{
    [key: number]: boolean;
  }>({});

  function sortTable(
    vendors: any,
    index: number,
    direction: "ascending" | "descending",
    numeric: boolean
  ): any {
    return [...vendors].sort((rowA, rowB) => {
      if (numeric) {
        return direction === "descending"
          ? rowB[index] - rowA[index]
          : rowA[index] - rowB[index];
      } else {
        return direction === "descending"
          ? rowB[index].localeCompare(rowA[index])
          : rowA[index].localeCompare(rowB[index]);
      }
    });
  }

  async function handleSort(
    index: number,
    direction: "ascending" | "descending"
  ) {
    var bool: boolean = false;
    if (index === 0 || index === 3) {
      bool = true;
    }
    var temp_payments = sortTable(payments, index, direction, bool);
    setPayments(temp_payments);
  }

  const [temp_row, setTempRow] = useState("");
  const [temp_index, setTempIndex] = useState(0);

  const toggleRow = async (index: number, row: any) => {
    setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] }));
    setPaymentsDetailsIndex(1);
    setPaymentsDetailsSize(10);
    setPaymentsMaxIndex(1);
    setPaymentsDetailsMaxIndex(1);
    setPaymentsPageSize(10);
    setTempRow(row);
    setTempIndex(index);
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
      if (!sublistData[index]) {
        setLoadingSublist((prev) => ({ ...prev, [index]: true }));
        try {
          const data = await fetchSublistData(index, row);
          setSublistData((prev) => ({ ...prev, [index]: data as any[] }));
        } catch (error) {
          console.error("Error fetching sublist data:", error);
        } finally {
          setLoadingSublist((prev) => ({ ...prev, [index]: false }));
        }
      }
    }
  };

  const fetchSublistData = async (index: number, row: any, page?: number) => {
    // Replace this with your actual API call
    return new Promise((resolve) => {
      let tempPage = page ? page : paymentsDetailsIndex;
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && this.status === 200) {
          var json = JSON.parse(this.responseText);
          if (row[3] && row[3] > 0) {
            setTotalPaymentsDetails(row[3]);
            if (row[3] > paymentsDetailsSize) {
              setPaymentsDetailsMaxIndex(
                Math.floor(row[3] / paymentsDetailsSize) + 1
              );
            } else {
              setPaymentsDetailsMaxIndex(1);
            }
          } else {
            setTotalPaymentsDetails(0);
          }
          let temp_data = json.data || [];
          temp_data = temp_data.map((item: any) => {
            if (item.status === "captured") {
              item.status = <p className="text-green">SUCCESS</p>;
            } else if (item.status === "refunded") {
              item.status = <p className="text-red">REFUNDED</p>;
            } else {
              item.status = item.status.toUpperCase();
            }
            let temp_date = new Date(item.created_at);
            temp_date.setHours(temp_date.getHours() + 5);
            temp_date.setMinutes(temp_date.getMinutes() + 30);
            item.created_at = temp_date.toISOString();
            return item;
          });
          resolve(temp_data);
        } else if (this.readyState === 4 && this.status === 401) {
          window.location.href = "/login";
          resolve([]);
        } else if (this.readyState === 4) {
          setFetchingPayments(false);
          resolve([]);
        }
      });
      xhr.open(
        "GET",
        ENDPOINT +
          "/v1/user/payments/details?qr_code=" +
          row[2] +
          "&date=" +
          row[1] +
          "&pageSize=" +
          paymentsDetailsSize +
          "&pageNumber=" +
          tempPage
      );
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", "Bearer " + userToken);
      xhr.send();
    });
  };

  async function handleSortPayouts(
    index: number,
    direction: "ascending" | "descending"
  ) {
    var bool: boolean = false;
    if (index === 0 || index === 7 || index === 5 || index === 6) {
      bool = true;
    }
    var temp_payouts = sortTable(payouts, index, direction, bool);
    setPayouts(temp_payouts);
  }

  const [lastMonthDates] = useState(() => {
    var temp_array = [];
    for (let index = -1; index < 90; index++) {
      var d = new Date();
      d.setDate(d.getDate() - index);
      var fullYear = d.getFullYear();
      var month: any = d.getMonth();
      var day: any = d.getDate();
      month = month + 1;
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      temp_array.push({
        label: fullYear + "-" + month + "-" + day,
        value: fullYear + "-" + month + "-" + day,
      });
    }
    return temp_array;
  });

  async function openLivePaymentsTab(id: any) {
    setActiveButtonIndex(id);
    switch (id) {
      case 0:
        if (!paymentsCalled) {
          fetchPayments();
        } else {
          setPaymentsIndex(1);
          setPaymentsPageSize(10);
          fetchPayments();
        }
        break;
      case 1:
        if (!payoutsCalled) {
          fetchPayouts();
        } else {
          setPayoutsIndex(1);
          setPaymentsPageSize(10);
          fetchPayouts();
        }
        break;
      default:
        break;
    }
  }

  async function handlePageChangePayments(event: any) {
    console.log(event);
    let temp = event.selected + 1;
    setPaymentsIndex(temp);
    setPayments([]);
    fetchPayments(temp);
  }

  async function handlePageChangePaymentsDetails(event: any) {
    console.log(event);
    let temp = event.selected + 1;
    setPaymentsDetailsIndex(temp);
    setSublistData({});
    const data = await fetchSublistData(temp_index, temp_row, temp);
    setSublistData((prev) => ({ ...prev, [temp_index]: data as any[] }));
  }

  async function fetchPayments(id?: any) {
    setFetchingPayments(true);
    var temp_id;
    if (id && id.toString().length > 0) {
      temp_id = id;
    } else {
      temp_id = paymentsIndex;
    }
    var currentDate = new Date();
    var hour = currentDate.getHours();
    if (hour > 20) {
      currentDate = new Date(currentDate.getTime() + 86400000);
    }
    var fullYear = currentDate.getFullYear();
    var month: any = currentDate.getMonth();
    var day: any = currentDate.getDate();
    month = month + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var start_date, end_date;
    start_date = fullYear + "-" + month + "-" + day;
    end_date = fullYear + "-" + month + "-" + day;
    let url = ENDPOINT + "/v1/user/payments?days=45";
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        var json = JSON.parse(this.responseText);
        if (json && json.total_count > 0) {
          setTotalPayments(json.total_count);
          if (json.total_count > paymentsPageSize) {
            setPaymentsMaxIndex(
              Math.floor(json.total_count / paymentsPageSize) + 1
            );
          } else {
            setPaymentsMaxIndex(1);
          }
        } else {
          setTotalPayments(0);
        }
        if (json && json.status && json.data && json.data.length > 0) {
          var temp_payments: any = [];
          var temp_arr = json.data;
          var totalAmount = 0;
          for (let index = 0; index < temp_arr.length; index++) {
            var element = temp_arr[index];
            var obj = [];
            totalAmount = totalAmount + element.total_payments;
            obj.push(index + 1);
            obj.push(element.payment_date);
            obj.push(element.qr_code);
            obj.push(element.total_payments);
            temp_payments.push(obj);
          }
          setTotalAmount(totalAmount);
          setPayments(temp_payments);
        } else {
          setPayments([]);
        }
        setFetchingPayments(false);
        setPaymentsCalled(true);
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
        setFetchingPayments(false);
      }
    });
    xhr.open("GET", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send();
  }

  async function handlePageChangePayouts(event: any) {
    console.log(event);
    let temp = event.selected + 1;
    setPayoutsIndex(temp);
    setPayouts([]);
    fetchPayouts("", temp);
  }

  function formatDateString(isoString: any) {
    const date: any = new Date(isoString);

    // Check for invalid date
    if (isNaN(date)) {
      return "Invalid Date";
    }

    // Define month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract date components
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Get timezone offset
    // const timezoneOffset = -date.getTimezoneOffset();
    // const timezoneSign = timezoneOffset >= 0 ? "+" : "-";
    // const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60)
    //   .toString()
    //   .padStart(2, "0");
    // const timezoneMinutes = (Math.abs(timezoneOffset) % 60)
    //   .toString()
    //   .padStart(2, "0");

    // Format the date string
    const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes}:${seconds} ${ampm}`;

    return formattedDate;
  }

  async function fetchPayouts(date?: any, id?: any) {
    setFetchingPayouts(true);
    var temp_id;
    if (id && id.toString().length > 0) {
      temp_id = id;
    } else {
      temp_id = payoutsIndex;
    }
    var currentDate = new Date();
    var fullYear: any = currentDate.getFullYear();
    var month: any = currentDate.getMonth();
    var day: any = currentDate.getDate();
    month = month + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var start_date: any, end_date: any;
    if (date && date.length > 0) {
      start_date = date;
      end_date = date;
    } else if (selectedDate && selectedDate.length > 0) {
      start_date = selectedDate;
      end_date = selectedDate;
    } else {
      start_date = fullYear + "-" + month + "-" + day;
      end_date = fullYear + "-" + month + "-" + day;
    }

    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        var json = JSON.parse(this.responseText);
        if (json && json.total_count > 0) {
          setTotalPayouts(json.total_count);
        } else {
          setTotalPayouts(0);
        }
        if (json.total_count > payoutsPageSize) {
          setPayoutsMaxIndex(
            Math.floor(json.total_count / payoutsPageSize) + 1
          );
        } else {
          setPayoutsMaxIndex(1);
        }
        if (json && json.status && json.data && json.data.length > 0) {
          var temp_payouts: any = [];
          var temp_arr = json.data;
          var totalPayoutAmount = 0,
            totalPayoutPaymentsTemp = 0,
            totalPayoutPaymentsTempComb = 0;
          for (let index = 0; index < temp_arr.length; index++) {
            var element = temp_arr[index];
            var obj = [];
            totalPayoutAmount = totalPayoutAmount + parseInt(element.amount);
            totalPayoutPaymentsTemp =
              totalPayoutPaymentsTemp +
              parseInt(element.total_count ? element.total_count : "0");
            totalPayoutPaymentsTempComb =
              totalPayoutPaymentsTempComb +
              parseInt(element.payout_amount ? element.payout_amount : "0");
            obj.push(index + 1);
            if (
              element.status === "processed" ||
              element.status === "reserved"
            ) {
              obj.push(
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  title="Processed"
                  className="text-green-600 text-lg"
                ></FontAwesomeIcon>
              );
            } else if (
              element.status === "failed" ||
              element.status === "rejected" ||
              element.status === "cancelled"
            ) {
              obj.push(
                <>
                  <FontAwesomeIcon
                    icon={faWarning}
                    title={
                      "Failed" + (element.notes ? " " + element.notes : "")
                    }
                    className="text-red-600 text-lg"
                  ></FontAwesomeIcon>
                  {element.status === "failed" && (
                    <FontAwesomeIcon
                      icon={faRefresh}
                      // spin
                      onClick={() =>
                        retryPayout(
                          temp_arr[index].qr_code_id,
                          temp_arr[index].payout_id
                        )
                      }
                      title="Retry Payout"
                      className="text-orange-600 text-lg pointer ml-2"
                    ></FontAwesomeIcon>
                  )}
                </>
              );
            } else {
              obj.push(
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  title="Processing"
                  className="text-orange-600 text-lg pointer"
                ></FontAwesomeIcon>
              );
            }
            obj.push(element.payout_id);
            obj.push(
              element.payments_count !== null ? element.payments_count : 0
            );
            obj.push(element.amount);
            let notes_str = "";
            let temp_notes = JSON.parse(element.notes);
            temp_notes = temp_notes[0];
            for (const key in temp_notes) {
              if (
                key !== "qrId" &&
                key !== "payments" &&
                key !== "total_amount"
              ) {
                notes_str = notes_str + key + " : " + temp_notes[key] + " , ";
              }
            }
            // obj.push(notes_str);
            obj.push(formatDateString(element.created_at));
            temp_payouts.push(obj);
          }
          console.log(temp_payouts);
          setTotalPayoutAmount(totalPayoutAmount);
          setTotalPayoutPayments(totalPayoutPaymentsTemp);
          setTotalPayoutPaymentsComb(totalPayoutPaymentsTempComb);
          setPayouts(temp_payouts);
        } else {
          setPayouts([]);
        }
        setFetchingPayouts(false);
        setPayoutsCalled(true);
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
        setFetchingPayouts(false);
      }
    });
    xhr.open("GET", API_URL + "/v1/user/payouts");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send();
  }

  const handleSublistSort =
    (index: number) =>
    (headerIndex: number, direction: "ascending" | "descending") => {
      // Implement sorting logic for sublist
      const sortedData = [...sublistData[index]].sort((a, b) => {
        if (a[headerIndex] < b[headerIndex])
          return direction === "ascending" ? -1 : 1;
        if (a[headerIndex] > b[headerIndex])
          return direction === "ascending" ? 1 : -1;
        return 0;
      });
      setSublistData((prev) => ({ ...prev, [index]: sortedData }));
    };

  async function retryPayout(qr_code_id?: any, payout_id?: any) {
    let data = {
      qr_code_id,
      payout_id,
    };
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        fetchPayouts();
        toast.success("Payout processed successfully", toastr_options);
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
        toast.error("Please try again later", toastr_options);
      }
    });
    xhr.open("POST", API_URL + "/v1/process-payout");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send(JSON.stringify(data));
  }

  useEffect(() => {
    var user_obj: any = localStorage.getItem("userObj");
    try {
      user_obj = JSON.parse(user_obj);
      if (user_obj && user_obj.username && user_obj.username.length > 0) {
        setUsername(user_obj.username);
      }
      const location =
        typeof window !== "undefined" ? window.location.href : "";
    } catch (error) {}
    fetchPayments();
  }, [activeButtonIndex]);

  const location = typeof window !== "undefined" ? window.location.href : "";

  return (
    <AppProvider i18n={enTranslations}>
      <div className="dashboard-home">
        <div className="mt-1 mb-1 inline-block">
          <div className="float-left mr-2 smaller-dropdown">
            <ButtonGroup segmented>
              <Button
                pressed={activeButtonIndex === 0}
                onClick={() => openLivePaymentsTab(0)}
              >
                Payments
              </Button>
              <Button
                pressed={activeButtonIndex === 1}
                onClick={() => openLivePaymentsTab(1)}
              >
                Payouts
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="inline-block w-full mt-2 mb-2">
          {activeButtonIndex === 0 && (
            <>
              {fetchingPayments && (
                <div className="inline-block w-full text-center">
                  <Spinner
                    accessibilityLabel="Fetching Payments"
                    size="large"
                  />
                </div>
              )}
              {!fetchingPayments &&
                paymentsCalled &&
                (!payments || payments.length === 0) && (
                  <div className="w-full inline-block mt-2 mb-2">
                    <label className="w-full inline-block float-left text-center text-xl font-medium mt-2">
                      Currently There are no payment available
                    </label>
                  </div>
                )}
              {!fetchingPayments && paymentsCalled && payments.length > 0 && (
                <>
                  {/* <div className="w-full inline-block mt-2 mb-2">
                    <FontAwesomeIcon
                      className="float-right text-lg cursor-pointer"
                      icon={faRefresh}
                      onClick={fetchPayments}
                    ></FontAwesomeIcon>
                  </div> */}
                  <DataTable
                    columnContentTypes={["numeric", "text", "text", "numeric"]}
                    headings={[
                      "Sr. No.",
                      "Date",
                      "QR ID",
                      "Total No. of Payments",
                    ]}
                    rows={payments.flatMap((row, index) => [
                      [
                        <div
                          key={`row-${index}-0`}
                          onClick={() => toggleRow(index, row)}
                          className="cursor-pointer"
                        >
                          {row[0]}
                        </div>,
                        <div
                          key={`row-${index}-1`}
                          onClick={() => toggleRow(index, row)}
                          className="cursor-pointer"
                        >
                          {row[1]}
                        </div>,
                        <div
                          key={`row-${index}-2`}
                          onClick={() => toggleRow(index, row)}
                          className="cursor-pointer"
                        >
                          {row[2]}
                        </div>,
                        <div
                          key={`row-${index}-3`}
                          onClick={() => toggleRow(index, row)}
                          className="cursor-pointer"
                        >
                          {row[3]}
                        </div>,
                      ],
                      expandedRow === index
                        ? [
                            <tr key={`sublist-${index}`} className="grid">
                              <td colSpan={4} className="w-full">
                                <div className="w-full">
                                  {loadingSublist[index] ? (
                                    <div className="flex justify-center items-center py-4">
                                      <Spinner
                                        accessibilityLabel="Loading sublist"
                                        size="small"
                                      />
                                    </div>
                                  ) : sublistData[index] ? (
                                    <div className="w-full p-4 bg-gray-100">
                                      <DataTable
                                        columnContentTypes={[
                                          "numeric",
                                          "text",
                                          "text",
                                          "text",
                                          "numeric",
                                          "text",
                                        ]}
                                        headings={[
                                          "ID",
                                          "UPI ID",
                                          "Time",
                                          "Payment Id",
                                          "Amount",
                                          "Status",
                                        ]}
                                        rows={sublistData[index].map(
                                          (item, innerIndex) => [
                                            innerIndex + 1,
                                            JSON.parse(item.sender_data).vpa,
                                            item.created_at
                                              .split("T")[1]
                                              .split(".")[0],
                                            item.payment_id,
                                            item.amount,
                                            item.status,
                                          ]
                                        )}
                                        sortable={[
                                          true,
                                          true,
                                          true,
                                          true,
                                          true,
                                          true,
                                        ]}
                                        defaultSortDirection="ascending"
                                        initialSortColumnIndex={0}
                                        onSort={handleSublistSort(index)}
                                      />
                                      <div className="w-full inline-block mt-2 mb-2">
                                        <label className="inline-block float-left pl-2 mt-5">
                                          {paymentsDetailsIndex +
                                            "-" +
                                            (totalPaymentsDetails >
                                            paymentsDetailsSize
                                              ? paymentsDetailsSize *
                                                paymentsDetailsIndex
                                              : totalPaymentsDetails) +
                                            " of " +
                                            totalPaymentsDetails +
                                            " payments"}
                                        </label>
                                        <div className="inline-block pull-right">
                                          <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={
                                              handlePageChangePaymentsDetails
                                            }
                                            initialPage={
                                              paymentsDetailsIndex - 1
                                            }
                                            pageCount={paymentsDetailsMaxIndex}
                                            previousLabel="Prev"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            disableInitialCallback={true}
                                            renderOnZeroPageCount={null}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>,
                          ]
                        : [],
                    ])}
                    sortable={[true, true, true, true]}
                    defaultSortDirection="ascending"
                    initialSortColumnIndex={0}
                    onSort={handleSort}
                  />
                  {/* {payments.map((row, index) => (
                    <Collapsible
                      key={index}
                      open={expandedRows[index]}
                      id={`sublist-${index}`}
                    >
                      {loadingSublist[index] ? (
                        <div className="text-center py-4">
                          <Spinner
                            accessibilityLabel="Loading sublist"
                            size="small"
                          />
                        </div>
                      ) : sublistData[index] ? (
                        <div className="p-4 bg-gray-100">
                          <DataTable
                            columnContentTypes={[
                              "numeric",
                              "text",
                              "text",
                              "text",
                              "numeric",
                              "text",
                            ]}
                            headings={[
                              "ID",
                              "UPI ID",
                              "Time",
                              "Payment Id",
                              "Amount",
                              "Status",
                            ]}
                            rows={sublistData[index].map((item, innerIndex) => [
                              innerIndex + 1,
                              JSON.parse(item.sender_data).vpa,
                              item.created_at.split("T")[1],
                              item.payment_id,
                              item.amount,
                              item.status,
                            ])}
                            sortable={[true, true, true, true]}
                            defaultSortDirection="ascending"
                            initialSortColumnIndex={0}
                            onSort={handleSublistSort(index)}
                          />
                        </div>
                      ) : null}
                    </Collapsible>
                  ))} */}
                  <div className="inline-block w-full mt-2 mb-2">
                    <label className="w-full text-sm float-left font-normal text-right mr-2">
                      Total Amount :{" "}
                      <span className="font-bold">{totalAmount}</span>
                    </label>
                  </div>
                  <div className="w-full inline-block mt-2 mb-2">
                    <label className="inline-block float-left pl-2 mt-5">
                      {paymentsIndex +
                        "-" +
                        (totalPayments > paymentsPageSize
                          ? paymentsPageSize * paymentsIndex
                          : totalPayments) +
                        " of " +
                        totalPayments +
                        " payments"}
                    </label>
                    <div className="inline-block pull-right">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageChangePayments}
                        initialPage={paymentsIndex - 1}
                        pageCount={paymentsMaxIndex}
                        previousLabel="Prev"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        disableInitialCallback={true}
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {activeButtonIndex === 1 && (
            <>
              {/* <div className="w-full inline-block mt-2 mb-2">
                <div className="inline-block w-4/12">
                  <Select
                    options={lastMonthDates}
                    value={selectedDate}
                    onChange={(opt) => handleDateChange(opt)}
                    label=""
                  ></Select>
                </div>
              </div> */}
              {fetchingPayouts && (
                <div className="inline-block w-full text-center">
                  <Spinner
                    accessibilityLabel="Fetching Payments"
                    size="large"
                  />
                </div>
              )}

              {!fetchingPayouts &&
                payoutsCalled &&
                (!payouts || payouts.length === 0) && (
                  <div className="w-full inline-block mt-2 mb-2">
                    <label className="w-full inline-block float-left text-center text-xl font-medium mt-2">
                      Currently There are no payouts available for this date
                    </label>
                  </div>
                )}
              {!fetchingPayouts && payoutsCalled && payouts.length > 0 && (
                <>
                  <DataTable
                    columnContentTypes={[
                      "numeric",
                      "text",
                      "text",
                      "numeric",
                      "numeric",
                      // "text",
                      "text",
                    ]}
                    headings={[
                      "Sr. No.",
                      "Status",
                      "Payout ID",
                      "Total No. of Payments",
                      "Payout Amount",
                      // "Notes",
                      "Success Timestamp",
                    ]}
                    rows={payouts}
                    sortable={[true, true, true, true, true, true]}
                    defaultSortDirection="ascending"
                    initialSortColumnIndex={0}
                    onSort={handleSortPayouts}
                  />
                  <div className="inline-block w-full mt-2 mb-2">
                    <label className="text-sm float-right font-normal text-right">
                      Total Amount :{" "}
                      <span className="font-bold">{totalPayoutAmount}</span>
                    </label>
                    <label className="text-sm float-right font-normal text-right mr-4">
                      Total No. of Payments :{" "}
                      <span className="font-bold">{totalPayoutPayments}</span>
                    </label>
                    <label className="text-sm float-right font-normal text-right mr-4">
                      Total Payout Payment :{" "}
                      <span className="font-bold">
                        {totalPayoutPaymentsComb}
                      </span>
                    </label>
                  </div>
                  <div className="w-full inline-block mt-2 mb-2">
                    <label className="inline-block float-left pl-2 mt-5">
                      {payoutsIndex +
                        "-" +
                        (totalPayouts > payoutsPageSize
                          ? payoutsPageSize * payoutsIndex
                          : totalPayouts) +
                        " of " +
                        totalPayouts +
                        " payouts"}
                    </label>
                    <div className="inline-block pull-right">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageChangePayouts}
                        initialPage={payoutsIndex - 1}
                        pageCount={payoutsMaxIndex}
                        previousLabel="Prev"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        disableInitialCallback={true}
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="inline-block w-full mt-2 mb-2"></div>
      </div>
      <ToastContainer />
    </AppProvider>
  );
};

export default UserDashboard;
