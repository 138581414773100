/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Text,
  AppProvider,
  Layout,
  Page,
  LegacyCard,
  FormLayout,
  TextField,
  EmptyState,
  Button,
  LegacyStack,
  DataTable,
  Spinner,
  Select,
  Checkbox,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useState, useEffect, useCallback } from "react";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./home.css";
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import {
  nameRegex,
  ifscRegex,
  emailRegex,
  phoneRegex,
  accountNumberRegex,
  deviceIdRegex,
} from "./config";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/config";
import { ENDPOINT } from "../../common/config";
import Cutter from "./../../assets/cutter.svg";

const Vendors = () => {
  const toastr_options: ToastOptions = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const [username, setUsername] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).username;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [userToken] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).token;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [vendors, setVendors] = useState([]);
  const [showCreateVendor, setShowCreateVendor] = useState(false);
  const [vendorUpdating, setVendorUpdating] = useState(false);
  const [selectedTitleText] = useState("Create Vendor");
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [plantId, setPlantId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [chargeableAmount, setChargeableAmount] = useState("");
  const [chargeableVendorAmount, setChargeableVendorAmount] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountNumberConfirm, setBankAccountNumberConfirm] = useState("");
  const [vendorsCalled, setVendorsCalled] = useState(false);
  const [fetchingVendors, setFetchingVendors] = useState(false);
  const [ifscCode, setIfscCode] = useState("");
  const [password, setPassword] = useState("");
  const [payoutState, setPayoutState] = useState<any>(true);
  const [totalVendors, setTotalVendors] = useState(0);
  const [vendorsIndex, setVendorsIndex] = useState(1);
  const [vendorsMaxIndex, setVendorsMaxIndex] = useState(1);
  const [vendorsPageSize] = useState(10);
  const [openVendorDeleteModal, setOpenVendorDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [deletingVendor, setDeletingVendor] = useState("");
  const [passwordType, setPasswordType] = useState<any>("password");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [totalAmount, setTotalAmount] = useState(0);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const handleChange = useCallback(
    (payoutState: boolean) => setPayoutState(payoutState),
    []
  );

  const [payoutOptions] = useState([
    {
      label: "Daily Payout",
      value: "daily",
    },
    {
      label: "Monthly Payout",
      value: "monthly",
    },
  ]);
  const [selectedPayout, setSelectedPayout] = useState("daily");
  const [oldPayout, setOldPayout] = useState("daily");

  function sortTable(
    vendors: any,
    index: number,
    direction: "ascending" | "descending",
    numeric: boolean
  ): any {
    return [...vendors].sort((rowA, rowB) => {
      if (numeric) {
        return direction === "descending"
          ? rowB[index] - rowA[index]
          : rowA[index] - rowB[index];
      } else {
        return direction === "descending"
          ? rowB[index].localeCompare(rowA[index])
          : rowA[index].localeCompare(rowB[index]);
      }
    });
  }

  async function handleSort(
    index: number,
    direction: "ascending" | "descending"
  ) {
    if (index === 0) {
      if (sortingOrder === "desc") {
        setSortingOrder("asc");
      } else {
        setSortingOrder("desc");
      }
      fetchVendors();
    } else {
      var bool: boolean = false;
      if (index === 4 || index === 5) {
        bool = true;
      }
      var temp_vendors = sortTable(vendors, index, direction, bool);
      setVendors(temp_vendors);
    }
  }

  async function handleCreateVendor() {
    setVendorName("");
    setVendorEmail("");
    setVendorPhone("");
    setPlantId("");
    setChargeableAmount("");
    setChargeableVendorAmount("");
    setBankAccountNumber("");
    setBankAccountNumberConfirm("");
    setVendorUpdating(false);
    setShowCreateVendor(true);
  }

  async function handleDeleteVendor(params: any) {
    setDeletingVendor(params);
    setOpenVendorDeleteModal(true);
  }

  async function closeDeleteModal() {
    setDeletingVendor("");
    setOpenVendorDeleteModal(false);
  }

  async function createVendor() {
    setCreateLoading(true);
    if (!vendorUpdating) {
      if (nameRegex.test(vendorName)) {
        if (emailRegex.test(vendorEmail)) {
          if (phoneRegex.test(vendorPhone)) {
            if (deviceIdRegex.test(plantId)) {
              if (accountNumberRegex.test(bankAccountNumber)) {
                if (!accountNumberRegex.test(bankAccountNumberConfirm)) {
                  setCreateLoading(false);
                  toast.error("Confirm bank account number", toastr_options);
                  return "";
                }
                if (bankAccountNumberConfirm !== bankAccountNumber) {
                  setCreateLoading(false);
                  toast.error(
                    "Bank account numbers are not matching",
                    toastr_options
                  );
                  return "";
                }
                if (ifscRegex.test(ifscCode)) {
                  var data = {
                    device_id: plantId,
                    name: vendorName,
                    email: vendorEmail,
                    phone: vendorPhone,
                    amount: chargeableAmount,
                    payout_amount: chargeableVendorAmount,
                    account_name: vendorName,
                    account_number: bankAccountNumber,
                    account_branch: "",
                    account_ifsc: ifscCode,
                    payout_type: selectedPayout,
                    type: "bank",
                    vpa: "",
                    password,
                    payout_state: payoutState ? "1" : "0",
                  };

                  var xhr = new XMLHttpRequest();
                  xhr.addEventListener("readystatechange", function () {
                    if (this.readyState === 4 && this.status === 200) {
                      var create_json = JSON.parse(this.responseText);
                      if (create_json.status) {
                        setCreateLoading(false);
                        toast.success(
                          "Vendor has been created successfully",
                          toastr_options
                        );
                        fetchVendors();
                        setShowCreateVendor(false);
                      } else {
                        setCreateLoading(false);
                        if (
                          create_json &&
                          create_json.message &&
                          create_json.message.length > 0
                        ) {
                          toast.error(create_json.message, toastr_options);
                        } else {
                          toast.error("Please try again later", toastr_options);
                        }
                      }
                    } else if (this.readyState === 4 && this.status === 401) {
                      window.location.href = "/login";
                    } else if (this.readyState === 4) {
                      setCreateLoading(false);
                      var json = JSON.parse(this.responseText);
                      if (json && json.message && json.message.length > 0) {
                        toast.error(json.message, toastr_options);
                      } else {
                        toast.error("Please try again later", toastr_options);
                      }
                    }
                  });

                  xhr.open("POST", API_URL + "/v1/add/vendor");
                  xhr.setRequestHeader("Content-Type", "application/json");
                  xhr.setRequestHeader("Authorization", "Bearer " + userToken);
                  xhr.send(JSON.stringify(data));
                } else {
                  setCreateLoading(false);
                  toast.error("Please enter a valid IFSC code", toastr_options);
                }
              } else {
                setCreateLoading(false);
                toast.error(
                  "Please enter a valid Bank account number",
                  toastr_options
                );
              }
            } else {
              setCreateLoading(false);
              toast.error("Please enter a valid plant id");
            }
          } else {
            setCreateLoading(false);
            toast.error("Please enter a valid phone");
          }
        } else {
          setCreateLoading(false);
          toast.error("Please enter a valid email");
        }
      } else {
        setCreateLoading(false);
        toast.error("Please enter a valid name");
      }
    } else {
      if (nameRegex.test(vendorName)) {
        if (emailRegex.test(vendorEmail)) {
          if (phoneRegex.test(vendorPhone)) {
            if (deviceIdRegex.test(plantId)) {
              var data_update: any = {
                name: vendorName,
                email: vendorEmail,
                phone: vendorPhone,
                device_id: plantId,
                payout_amount: chargeableVendorAmount,
                payout_type: selectedPayout,
                payout_state: payoutState ? "1" : "0",
              };
              if (passwordUpdated) {
                data_update.password = password;
              }
              data_update = JSON.stringify(data_update);
              if (selectedPayout !== oldPayout) {
                if (selectedPayout === "daily") {
                  toast.info(
                    "Please know that this month's pending payments will be processed tomorrow 9PM",
                    toastr_options
                  );
                } else if (selectedPayout === "monthly") {
                  toast.info(
                    "Please know that this month's remaining daily payments will be processed at the end of this month",
                    toastr_options
                  );
                }
              }
              var xhr_update = new XMLHttpRequest();
              xhr_update.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                  setCreateLoading(false);
                  toast.success(
                    "Vendor details have been updated successfully",
                    toastr_options
                  );
                  fetchVendors();
                  setShowCreateVendor(false);
                } else if (this.readyState === 4) {
                  var json = JSON.parse(this.responseText);
                  setCreateLoading(false);
                  if (json && json.message && json.message.length > 0) {
                    toast.error(json.message, toastr_options);
                  } else {
                    toast.error("Please try again later", toastr_options);
                  }
                }
              });

              xhr_update.open("PUT", ENDPOINT + "/v1/vendor/" + vendorId);
              xhr_update.setRequestHeader("Content-Type", "application/json");
              xhr_update.setRequestHeader(
                "Authorization",
                "Bearer " + userToken
              );
              xhr_update.send(data_update);
            } else {
              setCreateLoading(false);
              toast.error("Please enter a valid plant id");
            }
          } else {
            setCreateLoading(false);
            toast.error("Please enter a valid phone");
          }
        } else {
          setCreateLoading(false);
          toast.error("Please enter a valid email");
        }
      } else {
        setCreateLoading(false);
        toast.error("Please enter a valid name");
      }
    }
  }

  async function openUpdateVendor(params: any) {
    params = JSON.parse(params);
    setVendorName(params.name);
    setVendorId(params.id);
    setVendorEmail(params.email);
    setVendorPhone(params.phone);
    setPlantId(params.device_id);
    setBankAccountNumber(params.account_number);
    setBankAccountNumberConfirm(params.account_number);
    setIfscCode(params.account_ifsc);
    setPassword(params.password);
    setChargeableAmount(params.amount + "");
    setChargeableVendorAmount(params.payout_amount + "");
    if (!params.payout_type || params.payout_type.length === 0) {
      setSelectedPayout("daily");
    } else {
      setSelectedPayout(params.payout_type);
      setOldPayout(params.payout_type);
    }
    setVendorUpdating(true);
    setShowCreateVendor(true);
    if (params.payout_state && params.payout_state === "1") {
      setPayoutState(true);
    } else {
      setPayoutState(false);
    }
  }

  async function deleteVendor() {
    if (deletingVendor && deletingVendor.length > 0) {
      var xhr = new XMLHttpRequest();
      setDeleteLoading(true);
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && this.status === 200) {
          setDeleteLoading(false);
          toast.success("Vendor has been deleted successfully", toastr_options);
          setDeletingVendor("");
          fetchVendors();
          setOpenVendorDeleteModal(false);
        } else if (this.readyState === 4 && this.status === 401) {
          window.location.href = "/login";
        } else if (this.readyState === 4) {
          setDeleteLoading(false);
          var json = JSON.parse(this.responseText);
          setDeletingVendor("");
          setOpenVendorDeleteModal(false);
          if (json && json.message && json.message.length > 0) {
            toast.error(json.message, toastr_options);
          } else {
            toast.error("Please try again later", toastr_options);
          }
        }
      });

      xhr.open("DELETE", API_URL + "/v1/vendor?device_id=" + deletingVendor);
      xhr.setRequestHeader("Authorization", "Bearer " + userToken);
      xhr.send();
    } else {
      toast.error("Please try again later", toastr_options);
      setDeletingVendor("");
      setOpenVendorDeleteModal(false);
    }
  }

  useEffect(() => {
    var user_obj: any = localStorage.getItem("userObj");
    try {
      user_obj = JSON.parse(user_obj);
      if (user_obj && user_obj.username && user_obj.username.length > 0) {
        setUsername(user_obj.username);
      }
      const location =
        typeof window !== "undefined" ? window.location.href : "";
    } catch (error) {}
    if (!vendorsCalled) {
      fetchVendors();
    }
  }, [vendorsCalled]);

  const location = typeof window !== "undefined" ? window.location.href : "";

  async function handlePageChange(event: any) {
    console.log(event);
    let temp = event.selected + 1;
    setVendorsIndex(temp);
    setVendors([]);
    fetchVendors(temp);
  }

  async function changePayoutOption(val: string) {
    setSelectedPayout(val);
    // if (val === "daily" && vendorUpdating) {
    //   toast.info(
    //     "Please know that this month's pending payments will be processed tomorrow 9PM",
    //     toastr_options
    //   );
    // } else if (val === "monthly" && vendorUpdating) {
    //   toast.info(
    //     "Please know that this month's remaining daily payments will be processed at the end of this month",
    //     toastr_options
    //   );
    // }
  }

  async function changePassword(e: any) {
    setPasswordUpdated(true);
    setPassword(e);
  }

  async function fetchVendors(id?: any) {
    setFetchingVendors(true);
    var data = "";
    var temp_id;
    if (id && id.toString().length > 0) {
      temp_id = id;
    } else {
      temp_id = vendorsIndex;
    }
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        var json = JSON.parse(this.responseText);
        if (json && json.total_count > 0) {
          setTotalVendors(json.total_count);
          if (json.total_count > vendorsPageSize) {
            setVendorsMaxIndex(
              Math.floor(json.total_count / vendorsPageSize) + 1
            );
          } else {
            setVendorsMaxIndex(1);
          }
        } else {
          setTotalVendors(0);
        }
        if (json && json.status && json.data && json.data.length > 0) {
          var temp_vendors: any = [];
          var temp_arr = json.data;
          var totalAmount = 0;
          for (let index = 0; index < temp_arr.length; index++) {
            var element = temp_arr[index];
            var obj = [];
            totalAmount = totalAmount + parseInt(element.amount);
            obj.push(index + 1);
            obj.push(element.name);
            obj.push(element.device_id);
            obj.push(
              <a
                href={element.image_url}
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {element.qr_code_id}
              </a>
            );
            obj.push(element.amount + "");
            obj.push(element.payout_amount + "");
            obj.push(element.phone);
            obj.push(element.email);
            var actions_div = (
              <div className="inline-block w-full">
                <FontAwesomeIcon
                  icon={faEdit}
                  id={JSON.stringify(element)}
                  onClick={(e) => openUpdateVendor(e.currentTarget.id)}
                  className="float-left ml-1 mr-1 cursor-pointer"
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  id={element.device_id}
                  onClick={(e) => handleDeleteVendor(e.currentTarget.id)}
                  className="float-left ml-1 mr-1 cursor-pointer"
                />
              </div>
            );
            obj.push(actions_div);
            temp_vendors.push(obj);
          }
          setTotalAmount(totalAmount);
          setVendors(temp_vendors);
        } else {
          setVendors([]);
        }
        setFetchingVendors(false);
        setVendorsCalled(true);
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
        setFetchingVendors(false);
      }
    });
    xhr.open(
      "GET",
      API_URL +
        "/v1/vendors?pageNumber=" +
        temp_id +
        "&pageSize=" +
        vendorsPageSize +
        "&sort=" +
        sortingOrder
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send(data);
  }

  return (
    <AppProvider i18n={enTranslations}>
      <div className="discounts-home">
        <div className="inline-block w-full">
          {showCreateVendor ? (
            <Page
              fullWidth
              backAction={{
                content: "Vendors",
                onAction: () => setShowCreateVendor(false),
              }}
              title={selectedTitleText}
              compactTitle
            >
              <div className="vendor-left-section">
                <div className="inline-block w-full mb-2">
                  <LegacyCard title="Vendor" sectioned>
                    <LegacyStack vertical>
                      <div className="w-full inline-block mb-2">
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <TextField
                            label="Vendor Name"
                            placeholder="Vendor Name"
                            type="text"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            value={vendorName}
                            onChange={setVendorName}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <TextField
                            label="Vendor Email"
                            placeholder="Vendor Email"
                            type="text"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            value={vendorEmail}
                            onChange={setVendorEmail}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <TextField
                            label="Vendor Phone"
                            placeholder="Vendor Phone"
                            type="text"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            value={vendorPhone}
                            onChange={setVendorPhone}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <TextField
                            label="Plant ID"
                            placeholder="Plant ID (6 to 15 characters)"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            type="text"
                            value={plantId}
                            onChange={setPlantId}
                            disabled={vendorUpdating}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <TextField
                            label="Chargeable Amount to Customer"
                            placeholder="Chargeable Amount to Customer"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            type="number"
                            min={0}
                            max={100}
                            value={chargeableAmount}
                            disabled={vendorUpdating}
                            onChange={setChargeableAmount}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <TextField
                            label="Chargeable Amount to Vendor"
                            placeholder="Chargeable Amount to Vendor"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            type="number"
                            min={0}
                            max={100}
                            value={chargeableVendorAmount}
                            onChange={setChargeableVendorAmount}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <label className="w-full mb-2">
                            Bank Account Number{" "}
                            <span className="text-red-600 text-md">*</span>
                          </label>
                          <input
                            className="w-full b-a-inp border border-slate-400 rounded"
                            placeholder="Bank Account Number"
                            type="text"
                            onPaste={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                            value={bankAccountNumber}
                            disabled={vendorUpdating}
                            onChange={(e) =>
                              setBankAccountNumber(e.target.value)
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <label className="w-full mb-2">
                            Confirm Bank Account Number{" "}
                            <span className="text-red-600 text-md">*</span>
                          </label>
                          <input
                            className="w-full b-a-inp border border-slate-400 rounded"
                            placeholder="Confirm Bank Account Number"
                            type="password"
                            onPaste={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                            value={bankAccountNumberConfirm}
                            disabled={vendorUpdating}
                            onChange={(e) =>
                              setBankAccountNumberConfirm(e.target.value)
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <Select
                            options={payoutOptions}
                            value={selectedPayout}
                            // disabled={vendorUpdating}
                            onChange={(opt) => changePayoutOption(opt)}
                            label={
                              "Payout option" +
                              (selectedPayout === "daily"
                                ? " (Everyday 9PM)"
                                : " (Last day of the month 9PM)")
                            }
                          ></Select>
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <TextField
                            label="IFSC Code"
                            placeholder="IFSC Code"
                            type="text"
                            suffix={
                              <span className="text-red-600 text-md">*</span>
                            }
                            value={ifscCode}
                            disabled={vendorUpdating}
                            onChange={setIfscCode}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                          <TextField
                            label="Password"
                            placeholder="Password"
                            type={passwordType}
                            suffix={
                              <>
                                <span className="text-red-600 text-md mr-2">
                                  *
                                </span>
                                <span className="text-md">
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      setPasswordType(
                                        passwordType === "password"
                                          ? "text"
                                          : "password"
                                      );
                                    }}
                                    icon={
                                      passwordType === "password"
                                        ? faEye
                                        : faEyeSlash
                                    }
                                  ></FontAwesomeIcon>
                                </span>
                              </>
                            }
                            value={password}
                            onChange={(e) => changePassword(e)}
                            autoComplete="off"
                          />
                        </div>
                        <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                          <Checkbox
                            label={
                              payoutState ? "Disable Payout" : "Enable Payout"
                            }
                            checked={payoutState}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </LegacyStack>
                  </LegacyCard>
                </div>
              </div>

              <div className="inline-block w-full float-right mt-2 mb-4">
                <div className="inline-block float-right">
                  <button
                    className="rounded bg-emerald-700 text-white pt-2 pb-2 pl-4 pr-4"
                    disabled={createLoading}
                    onClick={createVendor}
                  >
                    {createLoading && (
                      <>
                        <svg
                          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin inline-block"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </>
                    )}
                    {vendorUpdating ? "Update" : "Save"}
                  </button>
                </div>
                <div className="inline-block float-right mr-2">
                  <Button onClick={() => setShowCreateVendor(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Page>
          ) : (
            <Page fullWidth>
              <Layout>
                <Layout.Section>
                  <div className="w-full inline-block">
                    <Text id="storeDetails" variant="headingMd" as="h2">
                      Vendors
                      <div className="float-right">
                        <Button
                          primary={true}
                          onClick={handleCreateVendor}
                          size="slim"
                        >
                          Create Vendor
                        </Button>
                      </div>
                    </Text>
                  </div>
                </Layout.Section>
                <Layout.Section>
                  <LegacyCard sectioned>
                    {fetchingVendors && (
                      <div className="inline-block w-full text-center">
                        <Spinner
                          accessibilityLabel="Fetching Payments"
                          size="large"
                        />
                      </div>
                    )}
                    {!fetchingVendors &&
                      vendorsCalled &&
                      (!vendors || vendors.length === 0) && (
                        <FormLayout>
                          <EmptyState
                            heading="Manage vendors"
                            action={{
                              content: "Create Vendor",
                              onAction: handleCreateVendor,
                            }}
                            image={Cutter}
                            fullWidth
                          >
                            <p>Create Vendor Here</p>
                          </EmptyState>
                        </FormLayout>
                      )}
                    {!fetchingVendors &&
                      vendorsCalled &&
                      vendors.length > 0 && (
                        <>
                          <DataTable
                            columnContentTypes={[
                              "numeric",
                              "text",
                              "text",
                              "text",
                              "numeric",
                              "numeric",
                              "numeric",
                              "text",
                            ]}
                            headings={[
                              "Sr. No.",
                              "Name",
                              "Plant ID",
                              "QR ID",
                              "Amount",
                              "Payout Amount",
                              "Phone",
                              "Email",
                              "Actions",
                            ]}
                            rows={vendors}
                            sortable={[
                              true,
                              true,
                              true,
                              false,
                              true,
                              true,
                              true,
                              true,
                            ]}
                            defaultSortDirection="descending"
                            initialSortColumnIndex={1}
                            onSort={handleSort}
                          />
                          <div className="w-full inline-block mt-2 mb-2">
                            <label className="inline-block float-left pl-2 mt-5">
                              {vendorsIndex +
                                "-" +
                                (totalVendors > vendorsPageSize
                                  ? vendorsPageSize * vendorsIndex
                                  : totalVendors) +
                                " of " +
                                totalVendors +
                                " vendors"}
                            </label>
                            <div className="inline-block pull-right">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageChange}
                                initialPage={vendorsIndex - 1}
                                pageCount={vendorsMaxIndex}
                                previousLabel="Prev"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                disableInitialCallback={true}
                                renderOnZeroPageCount={null}
                              />
                            </div>
                          </div>
                        </>
                      )}
                  </LegacyCard>
                </Layout.Section>
              </Layout>
            </Page>
          )}
        </div>
      </div>
      <div>
        <Modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={openVendorDeleteModal}
          onClose={() => setOpenVendorDeleteModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openVendorDeleteModal}>
            <Box className="delete-vendor-modal">
              <div className="w-full inline-block">
                <div className="w-full inline-block">
                  <label className="w-full inline-block mt-2 mb-2 text-md text-black">
                    Are you sure you want to delete this vendor?
                  </label>
                </div>
                <div className="w-full inline-block mt-2 mb-2">
                  <div className="inline-block float-right">
                    <button
                      className="rounded bg-emerald-700 text-white pt-2 pb-2 pl-4 pr-4"
                      disabled={deleteLoading}
                      onClick={deleteVendor}
                    >
                      {deleteLoading && (
                        <>
                          <svg
                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin inline-block"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                      )}
                      Yes, Delete
                    </button>
                  </div>
                  <div className="inline-block float-right mr-2">
                    <Button onClick={closeDeleteModal}>No</Button>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </AppProvider>
  );
};

export default Vendors;
