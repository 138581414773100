/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Text,
  AppProvider,
  Layout,
  Page,
  LegacyCard,
  FormLayout,
  EmptyState,
  DataTable,
  Spinner,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./home.css";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/config";
import Cutter from "./../../assets/cutter.svg";

const SheetView = () => {
  const [username, setUsername] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).username;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [userToken] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).token;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [payments, setPayments] = useState([]);
  const [paymentsCalled, setPaymentsCalled] = useState(false);
  const [fetchingPayments, setFetchingPayments] = useState(false);
  const [totalPayments, setTotalPayments] = useState(0);
  const [paymentsIndex, setPaymentsIndex] = useState(1);
  const [paymentsMaxIndex, setPaymentsMaxIndex] = useState(1);
  const [paymentsPageSize] = useState(10);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [totalAmount, setTotalAmount] = useState(0);
  const [headings, setHeadings] = useState([]);
  const [headingTypes, setHeadingTypes] = useState<any>([]);
  const [value, setValue] = useState<any>(() => {
    let arr = [];
    let currentDate = new Date();
    let last_date = new Date().setDate(currentDate.getDate() - 90);
    let endDate = new Date(last_date);
    arr = [endDate, currentDate];
    return arr;
  });

  function sortTable(
    payments: any,
    index: number,
    direction: "ascending" | "descending",
    numeric: boolean
  ): any {
    return [...payments].sort((rowA, rowB) => {
      if (numeric) {
        return direction === "descending"
          ? rowB[index] - rowA[index]
          : rowA[index] - rowB[index];
      } else {
        return direction === "descending"
          ? rowB[index].localeCompare(rowA[index])
          : rowA[index].localeCompare(rowB[index]);
      }
    });
  }

  async function handleSort(
    index: number,
    direction: "ascending" | "descending"
  ) {
    if (index === 0) {
      if (sortingOrder === "desc") {
        setSortingOrder("asc");
      } else {
        setSortingOrder("desc");
      }
      fetchPayments();
    } else {
      var bool: boolean = false;
      if (index === 4 || index === 5) {
        bool = true;
      }
      var temp_payments = sortTable(payments, index, direction, bool);
      setPayments(temp_payments);
    }
  }

  useEffect(() => {
    var user_obj: any = localStorage.getItem("userObj");
    try {
      user_obj = JSON.parse(user_obj);
      if (user_obj && user_obj.username && user_obj.username.length > 0) {
        setUsername(user_obj.username);
      }
      const location =
        typeof window !== "undefined" ? window.location.href : "";
      console.log(location);
    } catch (error) {}
    if (!paymentsCalled) {
      fetchPayments();
    }
  }, [paymentsCalled]);

  const location = typeof window !== "undefined" ? window.location.href : "";
  console.log(location);

  async function handlePageChange(event: any) {
    console.log(event);
    let temp = event.selected + 1;
    setPaymentsIndex(temp);
    setPayments([]);
    fetchPayments(temp);
  }

  async function handleSelect(ranges: any) {
    console.log(ranges);
    setValue(ranges);
  }

  async function exportToCSV() {
    let csv: any,
      items = payments,
      temp_dates = headings;
    csv = "Serial Number,QR Code,";
    for (let index = 2; index < temp_dates.length; index++) {
      let date = temp_dates[index];
      csv = csv + date + ",";
    }
    csv = csv + "\r\n";
    for (let row = 0; row < items.length; row++) {
      let keysAmount = Object.keys(items[row]).length;
      let keysCounter = 0;
      for (let key in items[row]) {
        csv += items[row][key] + (keysCounter + 1 < keysAmount ? "," : "\r\n");
        keysCounter++;
      }
      keysCounter = 0;
    }
    let link = document.createElement("a");
    link.id = "download-csv";
    link.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute("download", "payments.csv");
    document.body.appendChild(link);
    let elem: any = document.querySelector("#download-csv");
    elem.click();
  }

  async function fetchPayments(id?: any) {
    setFetchingPayments(true);
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        console.log(this.responseText);
        var json = JSON.parse(this.responseText);
        if (json && json.total_count > 0) {
          setTotalPayments(json.total_count);
          if (json.total_count > paymentsPageSize) {
            setPaymentsMaxIndex(
              Math.floor(json.total_count / paymentsPageSize) + 1
            );
          } else {
            setPaymentsMaxIndex(1);
          }
        } else {
          setTotalPayments(0);
        }
        if (json && json.status && json.data && json.data.length > 0) {
          var temp_payments: any = [];
          var temp_arr = json.data;
          var totalAmount = 0;
          let unique_dates: any = [],
            unique_types: any = [],
            unique_qr_codes: any = [];
          for (let index = 0; index < temp_arr.length; index++) {
            let elem_u = temp_arr[index];
            if (unique_dates.indexOf(elem_u.payment_date) === -1) {
              unique_dates.push(elem_u.payment_date);
              unique_types.push("string");
            }
            if (unique_qr_codes.indexOf(elem_u.qr_code) === -1) {
              unique_qr_codes.push(elem_u.qr_code);
            }
          }
          let final_payments: any = [];

          for (let index = 0; index < unique_qr_codes.length; index++) {
            let qr_code = unique_qr_codes[index];
            let qr_arr: any = [];
            qr_arr.push(index + 1);
            qr_arr.push(qr_code);
            let temp = unique_dates.map((item: any, innerIndex: any) => {
              let total_payments_val = 0;
              for (let index = 0; index < temp_arr.length; index++) {
                let payment_obj = temp_arr[index];
                if (
                  payment_obj.payment_date === item &&
                  payment_obj.qr_code === qr_code
                ) {
                  total_payments_val = payment_obj.total_payments;
                }
              }
              qr_arr.push(total_payments_val);
              return item;
            });
            final_payments.push(qr_arr);
          }
          console.log(final_payments);
          unique_dates.unshift("S.No", "QR Code");
          unique_types.unshift("string", "string");
          setHeadings(unique_dates);
          setHeadingTypes(unique_types);
          setTotalAmount(totalAmount);
          setPayments(final_payments);
        } else {
          setPayments([]);
        }
        setFetchingPayments(false);
        setPaymentsCalled(true);
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
        setFetchingPayments(false);
      }
    });
    xhr.open("GET", API_URL + "/v1/payments/xlview?days=90");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send();
  }

  return (
    <AppProvider i18n={enTranslations}>
      <div className="discounts-home">
        <div className="inline-block w-full">
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                <div className="w-full inline-block">
                  <Text id="storeDetails" variant="headingMd" as="h2">
                    Payments
                  </Text>
                  <button
                    className="rounded bg-emerald-700 text-white float-right pt-2 pb-2 pl-4 pr-4"
                    onClick={exportToCSV}
                  >
                    Export
                  </button>
                </div>
              </Layout.Section>
              <Layout.Section>
                <LegacyCard sectioned>
                  {fetchingPayments && (
                    <div className="inline-block w-full text-center">
                      <Spinner
                        accessibilityLabel="Fetching Payments"
                        size="large"
                      />
                    </div>
                  )}
                  {!fetchingPayments &&
                    paymentsCalled &&
                    (!payments || payments.length === 0) && (
                      <FormLayout>
                        <EmptyState
                          heading="Manage payments"
                          image={Cutter}
                          fullWidth
                        >
                          <p>Payments will be shown here</p>
                        </EmptyState>
                      </FormLayout>
                    )}
                  {!fetchingPayments &&
                    paymentsCalled &&
                    payments.length > 0 && (
                      <>
                        <DataTable
                          columnContentTypes={headingTypes}
                          headings={headings}
                          rows={payments}
                          defaultSortDirection="descending"
                          initialSortColumnIndex={1}
                          onSort={handleSort}
                        />
                        <div className="w-full inline-block mt-2 mb-2">
                          <label className="inline-block float-left pl-2 mt-5">
                            {paymentsIndex +
                              "-" +
                              (totalPayments > paymentsPageSize
                                ? paymentsPageSize * paymentsIndex
                                : totalPayments) +
                              " of " +
                              totalPayments +
                              " payments"}
                          </label>
                          <div className="inline-block pull-right">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next"
                              onPageChange={handlePageChange}
                              initialPage={paymentsIndex - 1}
                              pageCount={paymentsMaxIndex}
                              previousLabel="Prev"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              disableInitialCallback={true}
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </LegacyCard>
              </Layout.Section>
            </Layout>
          </Page>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </AppProvider>
  );
};

export default SheetView;
