import "../css/App.css";
import Main from "./Main";
import Login from "./Login/Login";
import "@shopify/polaris/build/esm/styles.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    if (window.location.hostname.indexOf("quenchit") > -1) {
      document.title = "Quench It";
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<Main />}></Route>
        <Route path="/dashboard" element={<Main />}></Route>
        <Route path="/plants/:id" element={<Main />}></Route>
        <Route path="/profile" element={<Main />}></Route>
        <Route path="/vendors" element={<Main />}></Route>
        <Route path="/sheet-view" element={<Main />}></Route>
        <Route path="*" element={<Login />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
