/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppProvider,
  Page,
  LegacyCard,
  TextField,
  LegacyStack,
  Select,
  Checkbox,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useState, useEffect, useCallback } from "react";
import "./home.css";
import { ENDPOINT } from "../../common/config";

const Profile = () => {
  const [userToken] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    var initialValue;
    try {
      initialValue = JSON.parse(user_obj).token;
    } catch (error) {
      initialValue = "";
      // nav("/login");
      window.location.href = "/login";
    }
    return initialValue || "";
  });
  const [selectedTitleText] = useState("Profile");
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [plantId, setPlantId] = useState("");
  const [chargeableAmount, setChargeableAmount] = useState("");
  const [chargeableVendorAmount, setChargeableVendorAmount] = useState("");
  const [payoutState, setPayoutState] = useState<any>(true);

  const handleChange = useCallback(
    (payoutState: boolean) => setPayoutState(payoutState),
    []
  );

  const [payoutOptions] = useState([
    {
      label: "Daily Payout",
      value: "daily",
    },
    {
      label: "Monthly Payout",
      value: "monthly",
    },
  ]);
  const [selectedPayout, setSelectedPayout] = useState("daily");

  useEffect(() => {
    fetchVendors();
  }, []);

  async function fetchVendors(id?: any) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        var json = JSON.parse(this.responseText);
        let params = json.data;
        setVendorName(params.name);
        setVendorEmail(params.email);
        setVendorPhone(params.phone);
        setPlantId(params.device_id);
        setChargeableAmount(params.amount + "");
        setChargeableVendorAmount(params.payout_amount + "");
        if (!params.payout_type || params.payout_type.length === 0) {
          setSelectedPayout("daily");
        } else {
          setSelectedPayout(params.payout_type);
        }
        if (params.payout_state && params.payout_state === "1") {
          setPayoutState(true);
        } else {
          setPayoutState(false);
        }
      } else if (this.readyState === 4 && this.status === 401) {
        window.location.href = "/login";
      } else if (this.readyState === 4) {
      }
    });
    xhr.open("GET", ENDPOINT + "/v1/user/me");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + userToken);
    xhr.send();
  }

  async function changePayoutOption(val: string) {
    setSelectedPayout(val);
  }

  return (
    <AppProvider i18n={enTranslations}>
      <div className="discounts-home">
        <div className="inline-block w-full">
          <Page fullWidth title={selectedTitleText} compactTitle>
            <div className="vendor-left-section">
              <div className="inline-block w-full mb-2">
                <LegacyCard title="Profile" sectioned>
                  <LegacyStack vertical>
                    <div className="w-full inline-block mb-2 profile-form">
                      <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                        <TextField
                          label="Name"
                          placeholder="Name"
                          type="text"
                          disabled
                          value={vendorName}
                          onChange={setVendorName}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                        <TextField
                          label="Email"
                          placeholder="Email"
                          type="text"
                          disabled
                          value={vendorEmail}
                          onChange={setVendorEmail}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                        <TextField
                          label="Phone"
                          placeholder="Phone"
                          type="text"
                          disabled
                          value={vendorPhone}
                          onChange={setVendorPhone}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                        <TextField
                          label="Plant ID"
                          placeholder="Plant ID (6 to 15 characters)"
                          type="text"
                          disabled
                          value={plantId}
                          onChange={setPlantId}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                        <TextField
                          label="Chargeable Amount to Customer"
                          placeholder="Chargeable Amount to Customer"
                          type="number"
                          disabled
                          min={0}
                          max={100}
                          value={chargeableAmount}
                          onChange={setChargeableAmount}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                        <TextField
                          label="Chargeable Amount to Vendor"
                          placeholder="Chargeable Amount to Vendor"
                          type="number"
                          disabled
                          min={0}
                          max={100}
                          value={chargeableVendorAmount}
                          onChange={setChargeableVendorAmount}
                          autoComplete="off"
                        />
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pr-2">
                        <Select
                          options={payoutOptions}
                          value={selectedPayout}
                          disabled
                          onChange={(opt) => changePayoutOption(opt)}
                          label={
                            "Payout option" +
                            (selectedPayout === "daily"
                              ? " (Everyday 9PM)"
                              : " (Last day of the month 9PM)")
                          }
                        ></Select>
                      </div>
                      <div className="inline-block w-6/12 mt-2 mb-2 pl-2">
                        <Checkbox
                          label={
                            payoutState ? "Payout Enabled" : "Payout Disabled"
                          }
                          checked={payoutState}
                          disabled
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>
          </Page>
        </div>
      </div>
    </AppProvider>
  );
};

export default Profile;
